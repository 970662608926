import React from 'react';
import FlexLayout from '../../../shared/components/Layout/FlexLayout';
import Header from './header/components';
import FeatureSection from './sections/features/FeatureSection';
import MainSection from './sections/MainSection';
import PartnerSection from './sections/partners/PartnerSection';
import styles from './main.module.scss';
import SecondSection from './sections/second/SecondSection';
import ThirdSection from './sections/third/ThirdSection';
import classNames from 'classnames';

function MainContainer() {
    return (
        <FlexLayout vertical align={'center'} className={styles['container']}>
            <FlexLayout
                vertical
                align={'center'}
                className={styles['top-layer']}
            >
                <Header />
                <MainSection />
            </FlexLayout>
            <FlexLayout
                vertical
                align={'center'}
                className={classNames(styles['body'], styles.fixed)}
            >
                <SecondSection />
            </FlexLayout>
            <FlexLayout
                vertical
                align={'center'}
                className={classNames(styles['body'], styles.fixed)}
            >
                <ThirdSection />
            </FlexLayout>
            <FlexLayout vertical align={'center'} className={styles['body']}>
                <FeatureSection />
            </FlexLayout>
            <FlexLayout
                vertical
                align={'center'}
                className={classNames(styles['body'], styles.fixed)}
                gap="32px"
            >
                <PartnerSection />
            </FlexLayout>
        </FlexLayout>
    );
}

export default MainContainer;

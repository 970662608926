import React from 'react';
import MyButton from '../../../../shared/components/Button/MyButton';
import styles from './mainSection.module.scss';
import MyLink from '../../../../shared/components/Link/MyLink';
import FlexLayout from '../../../../shared/components/Layout/FlexLayout';
import Title from '../../../../shared/components/Typography/Title';
import Body from '../../../../shared/components/Typography/Body';

const MainSection = () => {
    return (
        <FlexLayout vertical className={styles.main}>
            <FlexLayout vertical className={styles.body}>
                <FlexLayout
                    vertical
                    className={styles['title-container']}
                    gap={'16px'}
                >
                    <div className={styles.title}>
                        Top-tier investors will become your steadfast partners.
                    </div>
                    <div className={styles.sub}>
                        Clarify Your Business Strategy · Refine Your Investment
                        Strategy · Succeed in Business!
                    </div>
                </FlexLayout>

                <MyLink pageName={'mentorListPage'}>
                    <MyButton radius="md" className={styles['btn']}>
                        <Title level="title5">GET ACCESS</Title>
                    </MyButton>
                </MyLink>
            </FlexLayout>
        </FlexLayout>
    );
};

export default MainSection;

import React from 'react';
import styles from './index.module.scss';
import Title from '../../../../../shared/components/Typography/Title';
import FlexLayout from '../../../../../shared/components/Layout/FlexLayout';
import Layout from '../../../../../shared/components/Layout/Layout';
import Body from '../../../../../shared/components/Typography/Body';

const ThirdSection = () => {
    return (
        <>
            <div className={styles.title}>Ways to grow your business</div>
            <FlexLayout className={styles.container}>
                <FlexLayout gap={'8px'} align="flex-start" flex={'1 0 0'}>
                    <Title level="title3" className={styles.primary}>
                        01
                    </Title>
                    <FlexLayout vertical gap={'12px'} className={styles.desc}>
                        <Title level="title5">Receive feedback. </Title>
                        <Body level="body1Medium">
                            Receive feedback through reviewing IR Deck and
                            having 1:1 meetings.
                        </Body>
                    </FlexLayout>
                </FlexLayout>
                <FlexLayout gap={'8px'} align="flex-start" flex={'1 0 0'}>
                    <Title level="title3" className={styles.primary}>
                        02
                    </Title>
                    <FlexLayout vertical gap={'12px'} className={styles.desc}>
                        <Title level="title5">Revise and Request.</Title>
                        <Body level="body1Medium">
                            Revise the business plan and request investment
                            feedback from various investors.
                        </Body>
                    </FlexLayout>
                </FlexLayout>
                <FlexLayout gap={'8px'} align="flex-start" flex={'1 0 0'}>
                    <Title level="title3" className={styles.primary}>
                        03
                    </Title>
                    <FlexLayout vertical gap={'12px'} className={styles.desc}>
                        <Title level="title5">Achieve the objective.</Title>
                        <Body level="body1Medium">
                            Secure investment and successfully execute the
                            business plan.
                        </Body>
                    </FlexLayout>
                </FlexLayout>
            </FlexLayout>
        </>
    );
};

export default ThirdSection;

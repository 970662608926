import React from 'react';
import FlexLayout from '../../../../../shared/components/Layout/FlexLayout';
import Title from '../../../../../shared/components/Typography/Title';
// import DocumentIcon from '../../../../../../public/assets/images/svg/main_note_icon.svg';
// import VideoIcon from '../../../../../../public/assets/images/svg/main_video_icon.svg';
// import MentorIcon from '../../../../../../public/assets/images/svg/main_mentor_icon.svg';
// import MainMockupImg from '../../../../../../public/assets/images/png/main_mockup.png';
import styles from './index.module.scss';
import S3StaticImage from '../../../../../shared/components/CustomImage/S3StaticImage';

const FeatureSection = () => {
    return (
        <>
            <div className={styles.title}>Features</div>
            <FlexLayout className={styles.container} align="center">
                <FlexLayout className={styles.desc} vertical>
                    <FlexLayout gap={'20px'} align="center">
                        {/* <MentorIcon /> */}
                        <S3StaticImage
                            path={'/svg/main_mentor_icon.svg'}
                            alt={'mentor'}
                        />
                        <Title level="title5">Top-tier investors</Title>
                    </FlexLayout>
                    <FlexLayout gap={'20px'} align="center">
                        {/* <DocumentIcon /> */}{' '}
                        <S3StaticImage
                            path={'/svg/main_note_icon.svg'}
                            alt={'document'}
                        />
                        <Title level="title5">Online Document Review</Title>
                    </FlexLayout>
                    <FlexLayout gap={'20px'} align="center">
                        {/* <VideoIcon /> */}
                        <S3StaticImage
                            path={'/svg/main_video_icon.svg'}
                            alt={'video'}
                        />
                        <Title level="title5">1 : 1 Video Call</Title>
                    </FlexLayout>
                </FlexLayout>
                <S3StaticImage
                    containerClassName={styles.img}
                    path={'/png/main_mockup.png'}
                    alt={'main_mockup'}
                    // layout="intrinsic"
                />
                {/* <CustomImage
                    containerClassName={styles.img}
                    src={MainMockupImg}
                    alt="main mockup"
                /> */}
            </FlexLayout>
        </>
    );
};

export default FeatureSection;

import { NextSeo } from 'next-seo';
import pages from '../src/shared/constants/pages';
import MainContainer from '../src/domains/main/components';
import Footer from '../src/domains/footer/components';
import FooterWrapper from '../src/domains/footer/components/FooterWrapper';

const Home = () => {
    return (
        <>
            <NextSeo
                canonical={`${process.env.NEXT_PUBLIC_FRONT_URL}${pages.mainPage.url}`}
                openGraph={{
                    url: `${process.env.NEXT_PUBLIC_FRONT_URL}${pages.mainPage.url}`,
                }}
            />
            <FooterWrapper>
                <MainContainer />
            </FooterWrapper>
        </>
    );
};

// export const getStaticProps: GetStaticProps = async (context) => {
// const [allInfluencerList, normalProductList] = await fetchMainPage();
// //{ props: posts } 빌드타임에 받아서 Blog component로 보낸다
// return {
//   props: {
//     allInfluencerList,
//     normalProductList,
//   },
// };
// console.log('index.tsx getStaticProps');
// const queryClient = new QueryClient();

// await queryClient.prefetchQuery(
//     queryKeyWithoutMember.QUERY_KEY_INFLUENCER_LIST,
//     async () => await fetchAllInfluencerListWithSR(),
// );

// await queryClient.prefetchQuery(
//     queryKeyWithoutMember.QUERY_KEY_SHOP_NORMAL_PRODUCT_LIST,
//     async () => await fetchNormalProductListWithSR(),
// );

// return {
//     props: {
//         dehydratedState: dehydrate(queryClient),
//     },
//     revalidate: 120,
// };
// };

export default Home;

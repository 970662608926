import React from 'react';
import styles from './index.module.scss';
import Title from '../../../../../shared/components/Typography/Title';
import FlexLayout from '../../../../../shared/components/Layout/FlexLayout';
import CheckIcon from '../../../../../../public/assets/images/svg/input_success_filled.svg';
import Layout from '../../../../../shared/components/Layout/Layout';
const SecondSection = () => {
    return (
        <>
            <div className={styles.title}>
                Feeling overwhelmed with the next steps for growth?
            </div>
            <FlexLayout className={styles.desc}>
                <FlexLayout gap={'16px'} align="center">
                    <Layout className={styles.check}>
                        <CheckIcon fill="#fff" />
                    </Layout>
                    <Title level="title5">Can we secure investment?</Title>
                </FlexLayout>
                <FlexLayout gap={'16px'} align="center">
                    <Layout className={styles.check}>
                        <CheckIcon fill="#fff" />
                    </Layout>
                    <Title level="title5">Is our revenue model rational?</Title>
                </FlexLayout>
                <FlexLayout gap={'16px'} align="center">
                    <Layout className={styles.check}>
                        <CheckIcon fill="#fff" />
                    </Layout>
                    <Title level="title5">Is our product competitive?</Title>
                </FlexLayout>
                <FlexLayout gap={'16px'} align="center">
                    <Layout className={styles.check}>
                        <CheckIcon fill="#fff" />
                    </Layout>
                    <Title level="title5">
                        Are our sales plans appropriate?
                    </Title>
                </FlexLayout>
                <FlexLayout gap={'16px'} align="center">
                    <Layout className={styles.check}>
                        <CheckIcon fill="#fff" />
                    </Layout>
                    <Title level="title5">Is the market clear?</Title>
                </FlexLayout>
                <FlexLayout gap={'16px'} align="center">
                    <Layout className={styles.check}>
                        <CheckIcon fill="#fff" />
                    </Layout>
                    <Title level="title5">Can we exit successfully?</Title>
                </FlexLayout>
            </FlexLayout>
        </>
    );
};

export default SecondSection;

import React from 'react';
import S3StaticImage from '../../../../../shared/components/CustomImage/S3StaticImage';
import styles from './index.module.scss';

const PartnerSection = () => {
    const images = [
        `/png/partners_logo-02.png`,
        `/png/partners_logo-03.png`,
        `/png/partners_logo-04.png`,
        `/png/partners_logo-05.png`,
        `/png/partners_logo-06.png`,
        `/png/partners_logo-07.png`,
        `/png/partners_logo-08.png`,
        `/png/partners_logo-09.png`,
        `/png/partners_logo-10.png`,
    ];
    return (
        <>
            <div className={styles.title}>Partners</div>
            <div className={styles.logos}>
                {images.map((image, index) => (
                    <S3StaticImage
                        containerClassName={styles.img}
                        key={index}
                        path={image}
                        alt={'angelounge partner logo'}
                        layout={'intrinsic'}
                    />
                ))}
            </div>
        </>
    );
};

export default PartnerSection;
